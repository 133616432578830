import { Card, Collapse, Tabs } from "antd";
import { useEffect, useState } from "react";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import styled from "styled-components";
import { axios } from "../../api/axios";
import { BackButton } from "../../components/back-button/back-button.component";
import { sluggify } from "../../utils/sluggify";

type FAQ = { question: string; response: string; asset: string | null };

export const FAQPage = () => {
  const navigate = useNavigate();

  const { section } = useParams();
  const [faqs, setFaqs] = useState<Record<string, FAQ[]>>({});
  const [searchParams] = useSearchParams();

  useEffect(() => {
    axios
      .get<{ faqs: FAQ[]; category: string }[]>("/auth/get/faqs")
      .then((response) => {
        const mappedFaqs = response.data.reduce(
          (map, section) => ({ ...map, [section.category]: section.faqs }),
          {}
        );
        setFaqs(mappedFaqs);
      })
      .catch((e) => {
        console.log(e);
        alert("There was an error loading FAQs!");
      });
  }, []);

  return (
    <Main>
      {searchParams.get("webview") === "true" ? null : <BackButton />}
      <Tabs
        type="card"
        tabBarStyle={{ margin: "0 auto", marginBottom: "2rem" }}
        onChange={(activeTab) => {
          navigate(`/faq/${activeTab}`, { replace: true });
        }}
        {...(section ? { activeKey: section } : {})}
        items={Object.entries(faqs).map(([category, faqs], i) => ({
          key: sluggify(category),
          label: category,
          children: (
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                width: "100%",
                alignItems: "center",
              }}
            >
              <Collapse
                defaultActiveKey={faqs.map((_, j) => j.toString())}
                items={faqs.map((faq, j) => ({
                  key: j,
                  label: faq.question,
                  children: (
                    // <Card title={faq.question} style={{ marginBottom: "1rem" }}>
                    <div
                      dangerouslySetInnerHTML={{
                        __html: faq.response,
                      }}
                    ></div>
                    // </Card>
                  ),
                }))}
              ></Collapse>
            </div>
          ),
        }))}
      />
    </Main>
  );
};

const Main = styled.div`
  width: 100vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 4rem;
  padding-bottom: 0;
`;
